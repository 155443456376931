// src/firebase.js
import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  addDoc,
  getDocs,
  onSnapshot,
} from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyATy6VzVK3AtTI5mQwtZgGyhKy2jG5ldl8",
  authDomain: "moonlight-photography.firebaseapp.com",
  projectId: "moonlight-photography",
  storageBucket: "moonlight-photography.firebasestorage.app",
  messagingSenderId: "686347657844",
  appId: "1:686347657844:web:39dea6fc252a1c8906a721",
  measurementId: "G-PKD9WMGR75",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db, collection, addDoc, getDocs, onSnapshot };
