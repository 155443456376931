import { useEffect, useState } from "react";
import { Carousel } from "antd";
import { db, collection, onSnapshot } from "../firebase";
import { Link } from "react-router-dom";

const ReviewList = () => {
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, "reviews"), (snapshot) => {
      const updatedReviews = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setReviews(updatedReviews);
    });
    return () => unsubscribe();
  }, []);

  return (
    <div className="max-w-7xl mx-auto mt-8 p-6">
      <h2 className="text-3xl font-extrabold text-center text-white mb-8">
        Wat onze klanten zeggen over ons
      </h2>

      <div className="flex justify-center mb-8">
       <Link reloadDocument to={"review"}>
        <button className="py-3 px-5 text-sm text-center text-white bg-yellow-600 sm:w-fit hover:bg-yellow-700 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">
            Review Schijrven
          </button>
       </Link>
      </div>

      {reviews.length > 0 && (
        <Carousel
          autoplay
          autoplaySpeed={10000}
          slidesToShow={3}
          slidesToScroll={1}
          arrows
          className="carousel-container"
          responsive={[
            { breakpoint: 1024, settings: { slidesToShow: 2 } },
            { breakpoint: 768, settings: { slidesToShow: 1 } },
          ]}
        >
          {reviews
            .filter((review) => review.accepted)
            .map((review) => (
              <div key={review.id} className="p-4">
                <div className="bg-white h-[280px]  p-6 rounded-md shadow-lg border border-gray-200 hover:shadow-2xl transform transition-all duration-300 text-center">
                  <h3 className="text-xl font-semibold text-gray-900">
                    {review.username}
                  </h3>
                  <p className="text-gray-900 mt-4">{review.comment}</p>
                  <div className="flex justify-center space-x-1 my-2">
                    {[...Array(5)].map((_, index) => (
                      <span
                        key={index}
                        className={`text-2xl ${
                          index < review.rating
                            ? "text-yellow-500"
                            : "text-gray-300"
                        }`}
                      >
                        ★
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            ))}
        </Carousel>
      )}
    </div>
  );
};

export default ReviewList;
