import React from "react";
import Logo from "../img/header/logo.png";
import Socials from "./Socials";
import { Link as RouterLink } from "react-router-dom";
import { scroll } from "../helperFunctions/scroller";
import ReviewList from "./ReviewList";


const Footer = () => {
  return (
    <>
      <footer className="bg-white dark:bg-gray-900">
        <div className="container px-6 py-12 mx-auto">
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 sm:gap-y-10 lg:grid-cols-4">
            <div className="sm:col-span-2">
              <h1 className="max-w-lg text-xl font-semibold tracking-tight text-gray-800 xl:text-2xl dark:text-white">
                Abonneer u op onze nieuwsbrief om updates te ontvangen.
              </h1>

              <div className="flex flex-col mx-auto mt-6 space-y-3 md:space-y-0 md:flex-row">
                <input
                  disabled={true}
                  id="email"
                  type="text"
                  className="px-4 py-2 text-gray-700 bg-white border rounded-md dark:bg-gray-900 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 dark:focus:border-blue-300 focus:outline-none focus:ring focus:ring-opacity-40 focus:ring-blue-300"
                  placeholder="Email Address"
                />

                <button
                  disabled={true}
                  className="w-full px-6 py-2.5 text-sm font-medium tracking-wider text-white transition-colors duration-300 transform md:w-auto md:mx-4 focus:outline-none bg-gray-800 rounded-lg hover:bg-gray-700 focus:ring focus:ring-gray-300 focus:ring-opacity-80"
                >
                  Abonneer
                </button>
              </div>
            </div>

            <div>
              <p className="font-semibold text-gray-800 dark:text-white">
                Snelle Links
              </p>

              <div className="flex flex-col items-start mt-5 space-y-2">
                <RouterLink
                  className="text-gray-600 transition-colors duration-300 dark:text-gray-300 dark:hover:text-blue-400 hover:underline hover:text-blue-500"
                  to={"/"}
                  onClick={() => scroll("top")}
                >
                  Home
                </RouterLink>

                <RouterLink
                  className="text-gray-600 transition-colors duration-300 dark:text-gray-300 dark:hover:text-blue-400 hover:underline hover:text-blue-500"
                  to={"/portfolio"}
                  onClick={() => {
                    scroll("top");
                  }}
                >
                  Portfolio
                </RouterLink>
                <RouterLink
                  className="text-gray-600 transition-colors duration-300 dark:text-gray-300 dark:hover:text-blue-400 hover:underline hover:text-blue-500"
                  to={"/products"}
                  onClick={() => {
                    scroll("top");
                  }}
                >
                  Paketten
                </RouterLink>

                <RouterLink
                  className="text-gray-600 transition-colors duration-300 dark:text-gray-300 dark:hover:text-blue-400 hover:underline hover:text-blue-500"
                  to={"/about"}
                  onClick={() => {
                    scroll("top");
                  }}
                >
                  Wie zijn we
                </RouterLink>

                <RouterLink
                  className="text-gray-600 transition-colors duration-300 dark:text-gray-300 dark:hover:text-blue-400 hover:underline hover:text-blue-500"
                  to={"/philosophy"}
                  onClick={() => {
                    scroll("top");
                  }}
                >
                  Onze Philosophy
                </RouterLink>
              </div>
            </div>

            <div>
              <p className="font-semibold text-gray-800 dark:text-white">
                Meer informatie
              </p>

              <div className="flex flex-col items-start mt-5 space-y-2">
                <RouterLink
                  className="text-gray-600 transition-colors duration-300 dark:text-gray-300 dark:hover:text-blue-400 hover:underline hover:text-blue-500"
                  to={"/privacy-policy"}
                  onClick={() => scroll("top")}
                >
                  Privacy Policy
                </RouterLink>

                <RouterLink
                  className="text-gray-600 transition-colors duration-300 dark:text-gray-300 dark:hover:text-blue-400 hover:underline hover:text-blue-500"
                  to={"/terms-and-conditions"}
                  onClick={() => scroll("top")}
                >
                  Algemene Voorwaarden
                </RouterLink>
              </div>
            </div>
          </div>

          <hr className="my-6 border-gray-200 md:my-6 dark:border-gray-700" />

          <div>
            <ReviewList />
          </div>

          <div className="flex items-center justify-center">
            <RouterLink to={"/"} onClick={() => scroll("top")}>
              <img className="w-auto h-40" src={Logo} alt="logo" />
            </RouterLink>
          </div>

          <div className="flex items-center justify-center">
            <Socials size={24} color={"text-gray-300"} />
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
