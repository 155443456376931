import React from "react";
import { TiStarburst } from "react-icons/ti";
import { Link } from "react-router-dom";
import { scroll } from "../helperFunctions/scroller";

const PriceCard = ({ data }) => {
  let price = data.hasActiveDeal.active
    ? data.hasActiveDeal.newPrice
    : data.price;

  return (
    <div
      className={`flex flex-col transition duration-300 bg-white rounded shadow-sm hover:shadow`}
    >
      <div className="relative w-full h-48">
        <img
          src={data.image}
          className="object-cover object-center w-full h-full rounded-t"
          alt="Plan"
        />
      </div>
      <div className="flex flex-col justify-between flex-grow p-8 border border-t-0 rounded-b">
        <div>
          <div className="text-3xl font-title font-semibold text-center">
            {data.name}
          </div>

          <ul className="text-black list-disc py-4">
            {data.description.map((item) => (
              <li>
                {item == "Fotoboek" ? (
                  <Link
                    className="text-blue-500 hover:text-blue-700"
                    onClick={() => scroll("top")}
                    to={"/photo-book"}
                  >
                    {item}
                  </Link>
                ) : (
                  <p>{item}</p>
                )}
              </li>
            ))}
          </ul>
        </div>

        {/* <div className="relative text-4xl font-bold sm:text-5xl text-center bg-yellow-500 text-gray-900 w-[160px] mx-auto rounded py-2">
          {"€" + price}
          {data.hasActiveDeal.active && (
            <div className="relative inline-block">
              <span className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 text-white text-sm font-semibold px-2 py-1 rounded-full flex items-center">
                <TiStarburst
                  color="red"
                  size={50}
                  rotate={true}
                  className="text-xl mr-1"
                />
              </span>
              <span className="absolute top-0 right-1 transform translate-x-1/2 -translate-y-1/2 text-white text-sm line-through">
                €{data.price}
              </span>
              <span className="text-yellow-500 text-xl font-bold">
                €{data.newPrice}
              </span>
            </div>
          )}
        </div> */}
      </div>
    </div>
  );
};

export default PriceCard;
