import { useState, useEffect } from "react";
import { db } from "../firebase"; // Adjust the import based on your firebase setup
import { collection, getDocs, updateDoc, doc } from "firebase/firestore";

export default function AdminReviewPage() {
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [authenticated, setAuthenticated] = useState(false);
  const [password, setPassword] = useState("");

  const correctPassword = process.env.REACT_APP_ADMIN_PASSWORD

  useEffect(() => {
    if (authenticated) {
      const fetchReviews = async () => {
        try {
          const querySnapshot = await getDocs(collection(db, "reviews"));
          const filteredReviews = querySnapshot.docs
            .map((doc) => ({ id: doc.id, ...doc.data() }))
            .filter((review) => review.accepted === false && !review.reviewed);
          setReviews(filteredReviews);
        } catch (err) {
          setError("Fout bij het laden van reviews.");
        } finally {
          setLoading(false);
        }
      };
      fetchReviews();
    }
  }, [authenticated]);

  const handleReviewAction = async (id, accepted) => {
    try {
      await updateDoc(doc(db, "reviews", id), { accepted, reviewed: true });
      setReviews((prev) => prev.filter((review) => review.id !== id));
    } catch (err) {
      console.error("Error updating review:", err);
    }
  };

  if (!authenticated) {
    return (
      <div className="h-[500px] flex items-center justify-center bg-gray-100 p-6">
        <div className="bg-white shadow-lg rounded-lg p-8 w-full max-w-md text-center">
          <h2 className="text-xl font-semibold text-gray-800 mb-4">Voer Admin Wachtwoord In</h2>
          <input
            type="password"
            className="w-full p-3 border rounded-lg mb-3 focus:ring-2 focus:ring-blue-400 outline-none"
            placeholder="Wachtwoord"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button
            className="w-full py-3 px-5 text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg"
            onClick={() => password === correctPassword && setAuthenticated(true)}
          >
            Inloggen
          </button>
        </div>
      </div>
    );
  }

  if (loading) return <p className="text-center text-gray-600">Laden...</p>;
  if (error) return <p className="text-center text-red-600">{error}</p>;

  return (
    <div className="h-[500px] flex items-center justify-center bg-gray-100 p-6">
      <div className="bg-white shadow-lg rounded-lg p-8 w-full max-w-4xl">
        <h2 className="text-2xl font-semibold text-center text-gray-800 mb-6">
          Admin Review Beheer
        </h2>
        {reviews.length === 0 ? (
          <p className="text-center text-gray-600">Geen reviews om te beoordelen.</p>
        ) : (
          <ul className="space-y-4">
            {reviews.map((review) => (
              <li key={review.id} className="border p-4 rounded-lg bg-gray-50 flex flex-col sm:flex-row sm:justify-between sm:items-center">
                <div className="flex-1">
                  <p className="font-semibold text-lg">{review.username}</p>
                  <p className="text-gray-600 text-sm mt-1">{review.comment}</p>
                </div>
                <div className="mt-4 sm:mt-0 flex gap-2 justify-end sm:justify-start">
                  <button
                    className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 transition"
                    onClick={() => handleReviewAction(review.id, true)}
                  >
                    ✅ Accepteren
                  </button>
                  <button
                    className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition"
                    onClick={() => handleReviewAction(review.id, false)}
                  >
                    ❌ Weigeren
                  </button>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
}
